/**
* Generated automatically at built-time (2024-08-23T13:51:46.208Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-inox-range",templateKey: "sites/106-82f22c89-20de-4eb2-bf50-dde8eb2459f0"};