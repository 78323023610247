/**
* Generated automatically at built-time (2024-08-23T13:51:46.224Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-nordic-line",templateKey: "sites/106-88f5fe13-e4c9-47bd-aaee-861a64ff9c5b"};